<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM11 7.77294C10.626 7.86472 10.2603 8.01298 9.93461 8.23756C9.27647 8.69142 8.85715 9.41083 8.85715 10.3333C8.85715 11.2887 9.41062 11.8999 9.99849 12.2657C10.5111 12.5847 11.1526 12.7839 11.6449 12.9369C11.6645 12.9429 11.6838 12.9489 11.7029 12.9549C12.2752 13.1329 12.6743 13.264 12.9449 13.4324C13.0654 13.5074 13.1101 13.561 13.1248 13.5831L13.1253 13.584C13.1319 13.594 13.1428 13.6103 13.1428 13.6667C13.1428 13.9283 13.0475 14.0762 12.8987 14.1875C12.7187 14.3221 12.4093 14.4309 11.999 14.4313C11.3473 14.432 10.6686 14.1601 10.2593 13.6826L8.74076 14.9842C9.34604 15.6903 10.1665 16.1256 11 16.317V18H13V16.306C13.3909 16.2039 13.7666 16.0359 14.0966 15.7891C14.735 15.3115 15.1428 14.5776 15.1428 13.6667C15.1428 12.7113 14.5893 12.1001 14.0015 11.7343C13.4889 11.4154 12.8474 11.2161 12.3551 11.0632C12.3355 11.0571 12.3162 11.0511 12.2971 11.0452C11.7248 10.8671 11.3257 10.736 11.0551 10.5676C10.9346 10.4926 10.8899 10.439 10.8752 10.4169L10.8747 10.416C10.868 10.4061 10.8572 10.3897 10.8572 10.3333C10.8572 10.0832 10.9409 9.97305 11.07 9.88401C11.2475 9.76166 11.5686 9.65834 12.0228 9.64797C12.7444 9.63149 13.5016 9.85511 13.9266 10.1526L15.0734 8.51407C14.5001 8.1128 13.7562 7.84253 13 7.7208V6H11V7.77294Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
