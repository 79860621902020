<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.8335 19.9632C14.1371 20.5719 15.1687 20.3495 15.9428 19.9025C16.9347 19.3298 17.381 18.3732 17.5469 17.7187C17.5459 17.7174 17.5449 17.7161 17.5438 17.7148L16.1041 15.9702C16.0614 15.9185 15.9902 15.9004 15.928 15.9254L14.2598 16.597C13.107 17.0611 11.5734 17.0801 10.5783 15.8175C10.1469 15.2701 9.63127 14.5132 9.03685 13.4836C8.44243 12.454 8.04467 11.629 7.78634 10.9817C7.19047 9.48863 7.97373 8.16997 8.95203 7.40368L10.3677 6.29479C10.4205 6.25345 10.4404 6.18269 10.417 6.11989L9.62598 4.00075C9.6254 3.99918 9.6248 3.99767 9.62423 3.99624C9.00047 3.82215 7.9638 3.75294 6.9551 4.33531C6.1742 4.78616 5.46431 5.56205 5.33543 6.98064C5.20011 8.47016 5.69542 10.7812 7.70093 14.2549C9.70611 17.728 11.4669 19.325 12.8335 19.9632ZM11.9873 21.7753C10.0869 20.8879 8.06633 18.8878 5.96888 15.2549C3.87177 11.6226 3.15452 8.88127 3.34363 6.79969C3.53918 4.6472 4.6907 3.33326 5.95511 2.60326C7.60714 1.64945 9.28446 1.80309 10.2639 2.09952C10.9168 2.29714 11.3167 2.81104 11.4997 3.30135L12.2907 5.42049C12.6189 6.29984 12.3399 7.2905 11.601 7.86928L10.1853 8.97818C9.59789 9.43829 9.50256 9.8863 9.64388 10.2404C9.85885 10.7791 10.2107 11.5167 10.7689 12.4836C11.3271 13.4505 11.79 14.124 12.149 14.5795C12.385 14.8789 12.8207 15.0204 13.5129 14.7417L15.1811 14.0701C16.0518 13.7196 17.0492 13.9733 17.6466 14.6972L19.0864 16.4418C19.4195 16.8455 19.6646 17.4487 19.5092 18.113C19.2829 19.081 18.6136 20.67 16.9428 21.6346C15.6717 22.3685 13.9506 22.6922 11.9873 21.7753Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    size: { type: Number, required: false, default: 24 },
    fill: { type: String, required: false, default: '#1F284D' },
  },
};
</script>
