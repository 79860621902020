<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.75 13.8333V8.5H17.25V13.8333C17.25 15.168 16.168 16.25 14.8333 16.25L9.77942 16.25V10.5H11.2794V14.75L14.8333 14.75C15.3396 14.75 15.75 14.3396 15.75 13.8333Z"
      :fill="fill"
    />
    <path
      d="M8.25 15.5V9.25H11.804C12.3103 9.25 12.7207 9.66041 12.7207 10.1667V13.5H14.2207V10.1667C14.2207 8.83198 13.1387 7.75 11.804 7.75H6.75V15.5H8.25Z"
      :fill="fill"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0001 22.0002C17.523 22.0002 22.0002 17.523 22.0002 12.0001C22.0002 6.47719 17.523 2 12.0001 2C6.47719 2 2 6.47719 2 12.0001C2 17.523 6.47719 22.0002 12.0001 22.0002ZM12.0001 20.0002C16.4184 20.0002 20.0002 16.4184 20.0002 12.0001C20.0002 7.58176 16.4184 4 12.0001 4C7.58176 4 4 7.58176 4 12.0001C4 16.4184 7.58176 20.0002 12.0001 20.0002Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
