<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.8617 10.2241L11.4582 12.5412L13.7752 12.1377L17.7497 8.16323L15.8362 6.2497L11.8617 10.2241ZM17.2504 4.83549L19.1639 6.74903L20.06 5.85296C20.2553 5.65769 20.2553 5.34111 20.06 5.14585L18.8536 3.93942C18.6583 3.74415 18.3417 3.74415 18.1465 3.93941L17.2504 4.83549ZM14.7419 13.9994L21.4742 7.26718C22.4505 6.29087 22.4505 4.70795 21.4742 3.73164L20.2678 2.5252C19.2915 1.54889 17.7086 1.54889 16.7323 2.52519L9.99994 9.25745L9.36459 12.9058L9 14.9994L11.0936 14.6348L14.7419 13.9994ZM4 14.9996C4 13.3427 5.34315 11.9996 7 11.9996H8V13.9996H7C6.44772 13.9996 6 14.4473 6 14.9996C6 15.5519 6.44772 15.9996 7 15.9996H17C18.6569 15.9996 20 17.3427 20 18.9996C20 20.6564 18.6569 21.9996 17 21.9996H11V19.9996H17C17.5523 19.9996 18 19.5519 18 18.9996C18 18.4473 17.5523 17.9996 17 17.9996H7C5.34315 17.9996 4 16.6564 4 14.9996Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
